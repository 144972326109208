import { createSlice } from "@reduxjs/toolkit";

export const lightboxSlice = createSlice({
	name: "lightbox",
	initialState: {
		slides: [],
		isOpen: false,
	},
	reducers: {
		setSlides: (state, action) => {
			state.slides = action.payload;
		},
		showSlide: (state, action) => {
			const slide = {
				...(action.payload.type && { type: action.payload.type }),
				...{
					id: action.payload.id,
					name: action.payload.name,
					title: action.payload.title,
					annotations: action.payload.annotations,
					src: action.payload.src,
				},
			};

			state.slides = [slide];
			state.isOpen = true;
		},
		open: (state) => {
			state.isOpen = true;
		},
		close: (state) => {
			state.isOpen = false;
		},
	},
});

export const { setSlides, showSlide, open, close } = lightboxSlice.actions;

export default lightboxSlice.reducer;
