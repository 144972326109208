import React from "react";

export function ProjectFooterPlaceholder() {
	return (
		<div role="status">
			<div className="p-8 bg-white">
				<div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-48 mb-6"></div>
				<div className="h-2 bg-gray-100 rounded-full dark:bg-gray-200 mb-2.5"></div>
				<div className="h-2 bg-gray-100 rounded-full dark:bg-gray-200 mb-2.5"></div>
				<div className="h-2 bg-gray-100 rounded-full dark:bg-gray-200 mb-4"></div>
                <div className="flex">
                    <div className="h-2.5 bg-gray-100 w-8 rounded-full dark:bg-gray-200 mr-2"></div>
                    <div className="h-2.5 bg-gray-100 w-8 rounded-full dark:bg-gray-200"></div>
                </div>
			</div>
			<span className="sr-only">Loading...</span>
		</div>
	);
}
