import { configureStore } from "@reduxjs/toolkit";
import cmsSlice from "../components/content/cmsSlice";
import lightboxReducer from "../components/lightbox/lightboxSlice";
import projectSlice from "../components/project/projectSlice";
import scrollSlice from "../components/scroll/scrollSlice";
import pageSlice from "../pages/pageSlice";

export default configureStore({
	reducer: {
		lightbox: lightboxReducer,
		scroll: scrollSlice,
		page: pageSlice,
		cms: cmsSlice,
		project: projectSlice,
	},
});
