import React from "react";
import Label from "../content/Label";

export default function ProjectLabels({ project }) {
	return (
		<>
			{project?.company && (
				<div className="inline-flex gap-2">
					{project?.company && (
						<Label icon="company" colors="text-black bg-gray-100">
							{project?.company}
						</Label>
					)}

					{project?.date && (
						<Label icon="calendar" colors="text-black bg-gray-100">
							{project?.date}
						</Label>
					)}
				</div>
			)}
		</>
	);
}
