import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { durationMedium } from "src/utils/common";
import ChevronIcon from "../icons/ChevronIcon";
import { ProjectFooterPlaceholder } from "./ProjectFooterPlaceholder";
import ProjectLabels from "./ProjectLabels";

export function ProjectHeader({ project, isSelected }) {
	const navigate = useNavigate();
	const isLoading = useSelector((state) => state.project.isLoading);

	if (isLoading) return <ProjectFooterPlaceholder />;

	return (
		<AnimatePresence>
			{isSelected && (
				<div className="relative z-10 px-6 py-8 md:px-12 md:py-12">
					<motion.div
						className={
							isSelected
								? "container flex flex-col items-start mx-auto"
								: "flex flex-col p-6 items-start"
						}
						initial={{ opacity: 0, y: 100 }}
						animate={{ opacity: 1, y: 0 }}
						// exit={{ opacity: 0, y: 100 }}
						transition={{
							duration: durationMedium,
							delay: durationMedium * 2,
						}}
					>
						<Link
							to={".."}
							onClick={(e) => {
								e.preventDefault();

								if (
									window.history.state &&
									window.history.state.idx > 0
								) {
									navigate(-1);
								} else {
									navigate("/", {
										replace: true,
									});
								}
							}}
							className="inline-flex items-center pt-2 pb-2 text-sm"
						>
							<ChevronIcon className="w-4 h-4 -mt-1" />
							Work selection
						</Link>

						<div
							className={
								isSelected
									? "mb-1 text-3xl font-semibold md:text-4xl"
									: "pb-1 text-xl font-semibold"
							}
						>
							{project?.name}
						</div>

						<ProjectLabels project={project} />
					</motion.div>
				</div>
			)}
		</AnimatePresence>
	);
}
