import { motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { durationMedium } from "src/utils/common";
import CmsElement from "../components/content/CmsElement";
import ChevronIcon from "../components/icons/ChevronIcon";
import ProjectOverview from "../components/project/ProjectOverview";
import Page from "./Page";

export default function HomePage() {
	const projects = useSelector((state) => state.project.projects);
	const selectedProject = useSelector((state) => state.project.selected);

	return (
		<Page id="home">
			<motion.div
                style={{opacity: projects && projects.length > 0 ? 1 : 0}}
            >
				<section
					className={`flex flex-col items-center content-center max-w-4xl min-h-0 p-12 mx-auto text-center md:p-24 md:pb-32 lg:p-32`}
				>
					<motion.div
						className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-white pointer-events-none"
                        id="overlay"
						style={{ opacity: selectedProject ? 1 : 0}}
                        transition={{ duration: durationMedium }}
					></motion.div>
                    
					<CmsElement
						className="text-2xl"
						slug="intro"
						placeholder={"&nbsp;\n\n&nbsp;"}
					/>
					<Link
						to={"/about"}
						className="inline-flex items-center pt-2 pb-4 text-sm text-tertiary-100"
					>
						More about me
						<ChevronIcon className="w-4 h-4 -mt-1 rotate-180" />{" "}
					</Link>
				</section>

				<div className="px-6 pt-12 md:px-12 sm:pb-6 bg-gray-50">
					<div className="container">
						<h2 className="text-xl font-semibold uppercase text-md">
							Work selection
						</h2>
						<CmsElement className="text-lg" slug="work-intro" />
					</div>
				</div>
			</motion.div>

			<ProjectOverview />
		</Page>
	);
}
