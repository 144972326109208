import {
    motion,
    useScroll,
    useTransform,
} from "framer-motion";
import React, { useRef, useState } from "react";
import CustomerJourneyBreakdown from "src/components/visuals/customerJourney/CustomerJourneyBreakdown";
import Page from "./Page";

export default function TestPage(props) {
	const [variant, setVariant] = useState("journeyDetails");
	const steps = ["journeyDetails", "stepsBreakdown", "subNeeds", "closing"];

	function viewportLeave(slug) {
		const elementIndex = steps.findIndex((element) => element === slug);
		setVariant(steps[elementIndex - 1]);
	}

	return (
		<Page id="test" transitionDirection="right">
			<motion.div
				className="fixed z-10 flex flex-wrap content-end w-full h-full pb-24 -mt-24"
				// animate={{ scale: 0.8 }}
			>
				<CustomerJourneyBreakdown variant={variant} />
			</motion.div>

			<JourneySection
				title="Shopping journey"
				slug="journeyDetails"
				setVariant={setVariant}
				viewportLeave={viewportLeave}
			>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
			</JourneySection>
			<JourneySection
				title="Journey needs & functional needs"
				slug="stepsBreakdown"
				setVariant={setVariant}
				viewportLeave={viewportLeave}
                backgroundColor="bg-secondary-5"
			>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
			</JourneySection>
			<JourneySection
				title="Journey needs & functional needs"
				slug="subNeeds"
				setVariant={setVariant}
				viewportLeave={viewportLeave}
			>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
			</JourneySection>
			<JourneySection
				title="Closing words"
				slug="closing"
				setVariant={setVariant}
				viewportLeave={viewportLeave}
			>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
				<p className="pb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
			</JourneySection>
		</Page>
	);
}

export function JourneySection({
	title,
	setVariant,
	viewportLeave,
	slug,
    backgroundColor = "bg-primary-5",
	children,
}) {
	const ref = useRef(null);
	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ["start end", "end end"],
	});

	const opacity = useTransform(scrollYProgress, [0, 0.8, 0.9], [0, .5, 1]);

	return (
		<motion.section
			className={`sticky top-[100px] h-screen pt-16 pb-32 ${backgroundColor} md:px-12`}
			onViewportEnter={() => setVariant(slug)}
			onViewportLeave={() => viewportLeave(slug)}
			viewport={{margin: "-450px"}}
			ref={ref}
		>
			<motion.div
				className="container flex flex-col"
				style={{ opacity: opacity }}
			>
				<motion.h2 className="pb-3 text-lg font-semibold">
					{title}
				</motion.h2>

				{children && <div className="mt-auto">{children}</div>}
			</motion.div>
		</motion.section>
	);
}
