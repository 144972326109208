import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Headroom from "react-headroom";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { animateScroll } from "react-scroll";
import resolveConfig from "tailwindcss/resolveConfig";
import useBreakpoint from "use-breakpoint";
import tailwindConfig from "../../../tailwind.config.js";
import { objMap } from "../../utils/common.js";
import DownloadDocumentIcon from "../icons/DownloadDocumentIcon.jsx";
import Logo from "../icons/Logo.jsx";
import { setScrollPosition, setScrollTop } from "../scroll/scrollSlice.js";

const fullConfig = resolveConfig(tailwindConfig);

export default function Header() {
	let location = useLocation();
	const [logoX, setLogoX] = useState(0);
	const [menuX, setMenuX] = useState(0);
	const [logoScale, setLogoScale] = useState(1);
	const scrollPosition = useSelector((state) => state.scroll.scrollPosition);
	const scrollTop = useSelector((state) => state.scroll.scrollTop);
	const [headerPadding, setHeaderPadding] = useState(0);
	const headerRef = useRef();
	const headerContainerRef = useRef();
	const dispatch = useDispatch();
	const selectedClass =
		"text-tertiary-100 underline underline-offset-[6px] decoration-2";

	const { breakpoint } = useBreakpoint({
		...objMap(fullConfig.theme.screens, (size) => parseInt(size)),
		sm: 0,
	});

	function calculateHeaderDimensions() {
		setHeaderPadding(
			(headerRef?.current?.clientWidth -
				headerContainerRef?.current?.clientWidth) /
				2,
		);
	}

	const animateHeader = useCallback(() => {
		if (breakpoint !== "sm") {
			const targetScale = 0.8;
			const targetPadding = 24;
			const targetScrollPosition = 100;
			let targetX = headerPadding - targetPadding;

			let ratio = scrollPosition / targetScrollPosition;
			if (ratio > 1) ratio = 1;

			if (targetX > targetPadding) {
				setLogoX(targetX * ratio * -1);
				setMenuX(targetX * ratio);
				setLogoScale(1 - (1 - targetScale) * ratio);
			}
		}
	}, [breakpoint, headerPadding, scrollPosition]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		calculateHeaderDimensions();

		return () => {
			window.removeEventListener("resize", handleResize);
		};

		function handleResize() {
			calculateHeaderDimensions();
			animateHeader();
		}
	}, [dispatch, animateHeader]);

	useEffect(() => {
		animateHeader();
	}, [scrollPosition, scrollTop, headerPadding, animateHeader, location]);

	return (
		<>
			<header className="relative top-0 z-50 w-screen md:fixed" ref={headerRef}>
				<Headroom disable={breakpoint !== "sm"}>
					<div className="px-6 bg-white md:px-12 lg:bg-transparent">
						<nav
							className="container flex items-center justify-between w-full py-3 mx-auto md:py-6"
							ref={headerContainerRef}
						>
							<motion.div
								style={{ x: logoX, scale: logoScale }}
								className="origin-top-left"
							>
								<Link
									to="/"
                                    state={{previousPath: location.pathname}}
									className="flex w-16 md:w-full lg:flex-1"
									onClick={() => {
										animateScroll.scrollToTop({
											duration: 300,
											smooth: true,
										});
										dispatch(setScrollPosition(0));
										dispatch(setScrollTop(0));
									}}
								>
									<Logo />
								</Link>
							</motion.div>
							<motion.div style={{ x: menuX }}>
								<div className="flex items-center justify-end gap-x-6 sm:gap-x-8">
									<Link
										to="/"
                                        state={{previousPath: location.pathname}}
										className={`text-sm font-semibold leading-6 ${
											(location?.pathname === "/" ||
												location?.pathname.startsWith(
													"/project/",
												)) &&
											selectedClass
										} hover:cursor-pointer hover:text-tertiary-100`}
									>
										Work
									</Link>
									<Link
										to="/about"
                                        state={{previousPath: location.pathname}}
										className={` text-sm font-semibold leading-6 ${
											location?.pathname === "/about" &&
											selectedClass
										} hover:cursor-pointer hover:text-tertiary-100 underline-offset-[6px] underline-thickness-2`}
									>
										About
									</Link>
									<a
										href={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CV_URL}`}
										target="_blank"
										rel="noreferrer"
										className="text-sm font-semibold leading-6 text-gray-900 hover:cursor-pointer hover:text-tertiary-100"
									>
										<div className="inline-flex items-center py-2 text-sm">
											<span>
												<DownloadDocumentIcon className="w-5 h-5 pr-1 -mt-1" />
											</span>
											CV
										</div>
									</a>
								</div>
							</motion.div>
						</nav>
					</div>
				</Headroom>
			</header>
		</>
	);
}
