import React, { useEffect } from "react";
import { Routes as ReactRoutes, Route, useLocation } from "react-router-dom";
import AboutPage from "../pages/AboutPage";
import HomePage from "../pages/HomePage";
import TestPage from "../pages/TestPage";
import { logPageview } from "../utils/common";

export default function Routes() {
    const location = useLocation();
    // const locationArr = location.pathname?.split("/") ?? [];
    // const rootLocation = locationArr[1] === "" || locationArr[1]==='project' ? "home" : "about";

    useEffect(() => {
        logPageview(location.pathname);
	}, [location]);

	return (
		<ReactRoutes key={location.pathname} location={location}>
			<Route path="/" element={<HomePage />} />
			<Route path="/home" element={<HomePage />} />
			<Route path="/about" element={<AboutPage />} />
			<Route path="/project/:slug" element={<HomePage />} />
			<Route path="/test" element={<TestPage />} />
		</ReactRoutes>
	);
}
