import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Xwrapper } from "react-xarrows";
import {
    CustomerJourney,
    elementSizes,
    journeyTransition,
} from "./CustomerJourney.jsx";

export default function CustomerJourneyBreakdown(props) {
	const { variant } = props;
	const [breakdownVariant, setBreakdownVariant] = useState("journeyDetails");
	const variants = {
		journeyDetails: {
			x: elementSizes.small * -0.5,
		},
		stepsBreakdown: {
			x: elementSizes.small * -1,
		},
		subNeeds: {},
		closing: {},
	};

	useEffect(() => {
		setBreakdownVariant(variant);
	}, [variant]);

	return (
		<motion.div
			className="relative mx-auto"
			variants={variants}
			transition={journeyTransition}
			initial="journeyDetails"
			animate={breakdownVariant}
		>
			<Xwrapper>
				<CustomerJourney variant={variant} />
			</Xwrapper>
		</motion.div>
	);
}
