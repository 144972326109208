import React from "react";
import { useDispatch, useSelector } from "react-redux";
import YetAnotherLightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/plugins/captions.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import AnnotatedImage from "../content/AnnotatedImage";
import { close } from "./lightboxSlice";

export default function Lightbox() {
	const slides = useSelector((state) => state.lightbox.slides);
	const isOpen = useSelector((state) => state.lightbox.isOpen);
	const dispatch = useDispatch();

	return (
		<YetAnotherLightbox
			open={isOpen}
			close={() => dispatch(close())}
			slides={slides}
			controller={{ closeOnBackdropClick: true }}
			plugins={[Zoom]}
			zoom={{ scrollToZoom: true }}
			carousel={{ finite: true }}
			render={{
				buttonPrev: slides.length <= 1 ? () => null : undefined,
				buttonNext: slides.length <= 1 ? () => null : undefined,
				buttonZoom: () => {return null},
				slide: ({ slide }) => slide.type === "annotated-image" && <AnnotatedImage slide={slide} />
			}}
		/>
	);
}
