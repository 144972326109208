import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { durationMedium } from "src/utils/common";
import { ProjectFooterPlaceholder } from "./ProjectFooterPlaceholder";
import ProjectLabels from "./ProjectLabels";

export function ProjectFooter({ project, isSelected }) {
	const isLoading = useSelector((state) => state.project.isLoading);

	if (isLoading) return <ProjectFooterPlaceholder />;
    
    return (
        <AnimatePresence>
            {!isSelected && (
                <motion.div
                    className="flex flex-col items-start p-6 bg-white"
                    // layoutId={`${project?.id}-title-bla`}
                    initial={{ opacity: 0, y: -100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -100 }}
                    transition={{
                        duration: durationMedium,
                        delay: durationMedium,
                    }}
                >
                    <div className="pb-1 text-xl font-semibold">
                        {project?.name}
                    </div>

                    <div className="pb-3">{project?.summary}</div>
                    <ProjectLabels project={project} />
                </motion.div>
            )}
        </AnimatePresence>
    );
}
