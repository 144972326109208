import { motion } from "framer-motion";
import React, { useRef } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config.js";
import { convertRemToPixels } from "../../../utils/common.js";
import { CustomerJourneyElement } from "./CustomerJourneyElement.jsx";
import { JourneyArrow } from "./JourneyArrow.jsx";
import { NeedsGroup } from "./NeedsGroup.jsx";

export const fullConfig = resolveConfig(tailwindConfig);

// Variables
const animationDuration = 0.5;
const animationEasing = "easeInOut";

export const fontSizes = {
	small: fullConfig.theme.fontSize["xs"][0],
	medium: fullConfig.theme.fontSize["base"][0],
};

export const elementSizes = {
	small: convertRemToPixels(fullConfig.theme.width["20"]),
	medium: convertRemToPixels(fullConfig.theme.width["24"]),
	large: convertRemToPixels(fullConfig.theme.width["56"]),
};


export const journeyTransition = {
	duration: animationDuration,
	ease: animationEasing,
};

const defaultElementVariant = {
	x: 0,
	y: 0,
	opacity: 1,
	width: elementSizes.medium,
	height: elementSizes.medium,
};

export const defaultElementVariants = {
	journeyDetails: { ...defaultElementVariant },
	stepsBreakdown: { ...defaultElementVariant },
	subNeeds: { ...defaultElementVariant },
	closing: { ...defaultElementVariant, opacity: 0 },
};

const defaultNeedContainerVariant = {
	x: 0,
	y: 0,
	opacity: 1,
};

export const defaultNeedContainerVariants = {
	journeyDetails: { ...defaultNeedContainerVariant },
	stepsBreakdown: { ...defaultNeedContainerVariant },
	subNeeds: { ...defaultNeedContainerVariant },
	closing: { ...defaultNeedContainerVariant, opacity: 0 },
};

export const arrowVariants = {
	journeyDetails: {
		opacity: 1,
	},
	stepsBreakdown: {
		opacity: 0,
	},
	subNeeds: {
		opacity: 1,
	},
	closing: {
		opacity: 0,
	},
};

export function CustomerJourney({
	variant,
	phasesArrowOffsetX = 120,
	phasesArrowOffsetY = -120,
}) {
	const triggerRef = useRef();
	const needRef = useRef();
	const exploreRef = useRef();
	const evaluateRef = useRef();
	const decideRef = useRef();

	const phaseElementType = "primary";
	const otherElementType = "tertiary";

	return (
		<>
			<div className="flex items-center content-center w-full space-x-6">
				<CustomerJourneyElement
					ref={triggerRef}
					type={otherElementType}
					inverted={true}
					variant={variant}
					variants={{
						...defaultElementVariants,
						stepsBreakdown: {
							...defaultElementVariants.stepsBreakdown,
							x: -300,
							opacity: 0,
						},
					}}
					transition={journeyTransition}
				>
					Trigger
				</CustomerJourneyElement>

				<CustomerJourneyElement
					ref={needRef}
					type={otherElementType}
					inverted={true}
					variant={variant}
					variants={{
						...defaultElementVariants,
						stepsBreakdown: {
							...defaultElementVariants.stepsBreakdown,
							x: -200,
							opacity: 0,
						},
					}}
					transition={journeyTransition}
				>
					Need
				</CustomerJourneyElement>

				<NeedsGroup
					type={phaseElementType}
					variant={variant}
					groupLabel="Explore"
					firstNeedLabel="Formulate"
					secondNeedLabel="Browse"
					labelPosition="top"
					offsetDirection="top"
					ref={exploreRef}
				/>

				<NeedsGroup
					type={phaseElementType}
					variant={variant}
					groupLabel="Evaluate"
					firstNeedLabel="Compare"
					secondNeedLabel="Comprehend"
					labelPosition="bottom"
					offsetDirection="bottom"
					ref={evaluateRef}
				/>

				<CustomerJourneyElement
					ref={decideRef}
					type={phaseElementType}
					variant={variant}
					variants={{
						...defaultElementVariants,
						stepsBreakdown: {
							...defaultElementVariants.stepsBreakdown,
							x: 200,
							opacity: 0,
						},
					}}
					transition={journeyTransition}
				>
					Decide
				</CustomerJourneyElement>
			</div>

			<motion.div
				variants={arrowVariants}
				animate={variant}
				transition={journeyTransition}
			>
				<JourneyArrow start={triggerRef} end={needRef} />
				<JourneyArrow start={needRef} end={exploreRef} />
				<JourneyArrow
					start={exploreRef}
					end={evaluateRef}
					startAnchor="top"
					endAnchor="top"
					_cpx1Offset={phasesArrowOffsetX}
					_cpy1Offset={phasesArrowOffsetY}
				/>
				<JourneyArrow
					start={evaluateRef}
					end={exploreRef}
					startAnchor="bottom"
					endAnchor="bottom"
					_cpx1Offset={phasesArrowOffsetX * -1}
					_cpy1Offset={phasesArrowOffsetY * -1}
				/>
				<JourneyArrow start={evaluateRef} end={decideRef} />
			</motion.div>
		</>
	);
}
