import { AnimatePresence, motion, useMotionValue } from "framer-motion";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animateScroll } from "react-scroll";
import { durationFast, durationMedium } from "src/utils/common";
import { ProgressBar } from "../navigation/ProgressBar";
import { ProjectCard } from "./ProjectCard";
import ProjectContentItem from "./ProjectContentItem";
import { ProjectDetails } from "./ProjectDetails";
import { setPreviouslySelected } from "./projectSlice";

export const Project = forwardRef(function Project(
	{ project, isSelected = false, slug },
	ref,
) {
	const zIndex = useMotionValue(isSelected ? 2 : 0);
	const dispatch = useDispatch();
	const projectRef = React.useRef(null);
	useImperativeHandle(ref, () => projectRef?.current);

	const previouslySelected = useSelector(
		(state) => state.project.previouslySelected,
	);

	useEffect(() => {
		if (isSelected) {
			dispatch(setPreviouslySelected(project));

			document.body.style.overflow = "grid";
			return () => {
				document.body.style.overflow = "scroll";
			};
		}
	}, [isSelected, dispatch, project]);

	function checkZIndex(latest) {
		if (isSelected) {
			zIndex.set(30);
		} else if (!isSelected && latest.scaleX < 1.01) {
			zIndex.set(0);
		}
	}

	function animationStart() {
		const newIndex = slug
			? isSelected
				? 2
				: 0
			: project.id === previouslySelected.id
			? 2
			: 0;
		zIndex.set(newIndex);

		if (isSelected)
			animateScroll.scrollToTop({
				duration: 700,
				smooth: true,
			});
	}

	function animationComplete() {
		const newIndex = isSelected ? 2 : 0;
		zIndex.set(newIndex);
	}

	return (
		<motion.div
			layoutId={`project-${project?.id}`}
			// transition={{ duration: durationMedium }}
			onUpdate={checkZIndex}
			onLayoutAnimationStart={animationStart}
			onLayoutAnimationComplete={animationComplete}
			style={{ zIndex }}
			ref={projectRef}
			className={`grid w-full h-full overflow-scroll`}
			initial={isSelected ? "grid" : "selected"}
			animate={isSelected ? "selected" : "grid"}
			variants={{
				grid: {
					position: "relative",
					transition: { duration: durationMedium },
				},
				selected: {
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					paddingTop: isSelected ? "3rem" : "",
					// transition: {
					// 	duration: durationMedium,
					// 	left: { duration: durationMedium },
					// 	paddingTop: { duration: durationMedium },
					// 	top: { duration: durationMedium },
					// 	right: { duration: durationMedium },
					// },
				},
			}}
		>
			{isSelected && <ProgressBar containerRef={projectRef} />}
			<AnimatePresence>
				<ProjectCard project={project} isSelected={isSelected} />
			</AnimatePresence>

			{isSelected && (
				<motion.div
					initial="hidden"
					animate="visible"
					exit="hidden"
					variants={{
						hidden: { opacity: 0 },
						visible: { opacity: 1 },
					}}
					transition={{
						delay: durationMedium,
						duration: durationFast,
					}}
				>
					<ProjectDetails project={project} />

					{project?.content?.map((contentItem, index) => {
						return (
							<ProjectContentItem
								key={`${index}-${contentItem.id}`}
								project={project}
								contentItem={contentItem}
								content={project.content}
								index={index}
							/>
						);
					})}
				</motion.div>
			)}
		</motion.div>
	);
});
