import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { durationFast, durationMedium } from "src/utils/common";
import resolveConfig from "tailwindcss/resolveConfig";
import tinycolor from "tinycolor2";
import tailwindConfig from "../../../tailwind.config.js";
import HeroImage from "./HeroImage";

const fullConfig = resolveConfig(tailwindConfig);

export function Hero({ project, isSelected }) {
	const grey = fullConfig.theme.colors.gray["50"];
	const transparantGrey = tinycolor(fullConfig.theme.colors.gray["50"])
		.setAlpha(0)
		.toRgbString();

	return (
		<AnimatePresence>
			<motion.div
				className={`z-10 ${
					isSelected
						? "relative px-6 py-8 md:px-12 md:py-12 "
						: "relative"
				}`}
				id={`project-${project?.id}`}
				layoutId={project?.slug}
				initial={isSelected ? "grid" : "selected"}
				animate={isSelected ? "selected" : "grid"}
				variants={{
					grid: {
						backgroundColor: transparantGrey,
						transition: {
							backgroundColor: {
								duration: durationFast,
								ease: "easeOut",
							},
						},
					},
					selected: {
						backgroundColor: grey,
						transition: {
							backgroundColor: {
								delay: durationMedium,
								duration: durationFast,
							},
						},
					},
				}}
				// exit={{ opacity: 0 }}
			>
				<motion.div
					className="container relative z-10 flex"
					layoutId={`${project?.id}-image`}
					// transition={{ duration: .3 }}
				>
					<HeroImage
						image={project?.image?.data?.attributes}
						imagePosition={project.imagePosition}
						project={project}
					/>
				</motion.div>
			</motion.div>
		</AnimatePresence>
	);
}
