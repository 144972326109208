import { debounce } from "lodash";
import ReactGA from "react-ga4";

export const durationFast = 0.15;
export const durationMedium = 0.3;
export const durationSlow = 0.7;

export const pageTransition = {
    duration: durationSlow,
};

export const pageLeftVariants = {
    visible: { x: 0 },
    hidden: { x: "-100vw" },
};

export const pageRightVariants = {
    visible: { x: 0 },
    hidden: { x: "100vw" },
};

export const pageFadeVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};


export function bestImageSize(image, desiredSize) {
	const formats = image.formats;
	if (!formats) return;

	if (!desiredSize || desiredSize === "original") {
		return image;
	}

	if (desiredSize === "large" && formats["large"]) {
		return formats["large"];
	}

	if (
		(desiredSize === "large" || desiredSize === "medium") &&
		formats["medium"]
	) {
		return formats["medium"];
	}

	if (
		(desiredSize === "large" ||
			desiredSize === "medium" ||
			desiredSize === "small") &&
		formats["small"]
	) {
		return formats["small"];
	}

	if (formats["thumbnail"]) {
		return formats["thumbnail"];
	}
}

export function bestImageSizeFullUrl(image, desiredSize) {
	if (!image) return;

	return [
		process.env.REACT_APP_BACKEND_URL,
		bestImageSize(image, desiredSize)?.url,
	].join("");
}

export function objMap(obj, func) {
	return Object.fromEntries(
		Object.entries(obj).map(([k, v]) => [k, func(v)]),
	);
}

export function extendMarkdownFormatting(text) {
	return text.map((paragraph, index) => {
		const paragraphElements = paragraph.split(".");

		if (paragraphElements.length > 1) {
			return (
				<ol
					key={index}
					className="ml-5 list-decimal marker:text-primary-100"
					start={paragraphElements[0]}
				>
					<li>{paragraphElements[1]}</li>
				</ol>
			);
		}
		return paragraph;
	});
}

export const logPageview = debounce(gaPageview, 100);

export function gaPageview(path) {
	if (process.env.NODE_ENV === "production") {
		ReactGA.send({
			hitType: "pageview",
			page: path,
			title: path,
		});
	}
}

export function gaEvent(category, action, label) {
	if (process.env.NODE_ENV === "production") {
		ReactGA.send({
			hitType: "event",
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
		});
	}
}

export function convertRemToPixels(rem) {
    return parseInt(rem) * parseFloat(getComputedStyle(document.documentElement).fontSize);
}