import { motion } from "framer-motion";
import React, { forwardRef, useRef } from "react";
import {
    arrowVariants,
    defaultElementVariants,
    defaultNeedContainerVariants,
    elementSizes,
    fontSizes,
    journeyTransition,
} from "./CustomerJourney.jsx";
import { CustomerJourneyElement } from "./CustomerJourneyElement.jsx";
import { JourneyArrow } from "./JourneyArrow.jsx";

export const NeedsGroup = forwardRef(function NeedsGroup(
	{
		variant,
		groupLabel,
		firstNeedLabel,
		secondNeedLabel,
		offsetDirection = "top",
		labelPosition = "top",
		messyMiddleOffsetY = 30,
		type = "primary",
	},
	ref,
) {
	const offsetMultiplier = offsetDirection === "top" ? -1 : 1;
	const paddingForTextPosition = 120;

	function paddingTopForTextPosition(textPosition) {
		if (textPosition === "bottom") return paddingForTextPosition;
		return 0;
	}

	function paddingBottomForTextPosition(textPosition) {
		if (textPosition === "top") return paddingForTextPosition;
		return 0;
	}

	return (
		<motion.div
			className="flex flex-col items-center justify-center"
			variants={{
				journeyDetails: {
					paddingTop: elementSizes.small,
				},
				stepsBreakdown: {
					paddingTop: 0,
				},
				subNeeds: {
					paddingTop: elementSizes.small,
				},
			}}
			transition={journeyTransition}
		>
			<CustomerJourneyElement
				ref={ref}
				type={type}
				inverted={true}
				borderStyle="dashed"
				variant={variant}
				variants={{
					...defaultElementVariants,
					journeyDetails: {
						...defaultElementVariants.journeyDetails,
						y: messyMiddleOffsetY * offsetMultiplier,
						width: elementSizes.large,
						height: elementSizes.large,
						paddingTop: paddingTopForTextPosition(labelPosition),
						paddingBottom:
							paddingBottomForTextPosition(labelPosition),
					},
					stepsBreakdown: {
						...defaultElementVariants.stepsBreakdown,
						y: 0,
						paddingTop: paddingTopForTextPosition("center"),
						paddingBottom: paddingBottomForTextPosition("center"),
					},
					subNeeds: {
						...defaultElementVariants.subNeeds,
						y: messyMiddleOffsetY * offsetMultiplier,
						width: elementSizes.large,
						height: elementSizes.large,
						paddingTop: paddingTopForTextPosition(labelPosition),
						paddingBottom:
							paddingBottomForTextPosition(labelPosition),
					},
				}}
				transition={journeyTransition}
			>
				{groupLabel}
			</CustomerJourneyElement>

			<Needs
				type={type}
				variant={variant}
				variants={{
					...defaultNeedContainerVariants,
					journeyDetails: {
						...defaultNeedContainerVariants.journeyDetails,
						y:
							messyMiddleOffsetY * offsetMultiplier -
							elementSizes.large / 2 -
							elementSizes.small / 2,
					},
					subNeeds: {
						...defaultNeedContainerVariants.subNeeds,
						y:
							messyMiddleOffsetY * offsetMultiplier -
							elementSizes.large / 2 -
							elementSizes.small / 2,
					},
				}}
				transition={journeyTransition}
				firstLabel={firstNeedLabel}
				secondLabel={secondNeedLabel}
			/>
		</motion.div>
	);
});
function Needs(props) {
	const {
		variant,
		variants,
		type = "primary",
		firstLabel,
		secondLabel,
		arrowOffsetX = 60,
		arrowOffsetY = -100,
		needsOffsetY = 20,
	} = props;

	const leftRef = useRef();
	const rightRef = useRef();

	const needVariants = {
		journeyDetails: {
			width: elementSizes.small,
			height: elementSizes.small,
			fontSize: fontSizes.small,
		},
		stepsBreakdown: {
			width: elementSizes.medium,
			height: elementSizes.medium,
			fontSize: fontSizes.medium,
		},
		subNeeds: {
			width: elementSizes.small,
			height: elementSizes.small,
			fontSize: fontSizes.small,
		},
	};

	return (
		<>
			<motion.div
				className="flex justify-between w-full px-4 space-x-8"
				initial="journeyDetails"
				animate={variant}
				variants={variants}
				transition={journeyTransition}
			>
				<CustomerJourneyElement
					type={type}
					ref={leftRef}
					variant={variant}
					variants={{
						...needVariants,
						journeyDetails: {
							...needVariants.journeyDetails,
							y: needsOffsetY * -1,
						},
						stepsBreakdown: {
							...needVariants.stepsBreakdown,
							y: 0,
						},
						subNeeds: {
							...needVariants.subNeeds,
							y: needsOffsetY * -1,
						},
					}}
				>
					{firstLabel}
				</CustomerJourneyElement>
				<CustomerJourneyElement
					type={type}
					ref={rightRef}
					variant={variant}
					variants={{
						...needVariants,
						journeyDetails: {
							...needVariants.journeyDetails,
							y: needsOffsetY,
						},
						stepsBreakdown: {
							...needVariants.stepsBreakdown,
							y: 0,
						},
						subNeeds: {
							...needVariants.subNeeds,
							y: needsOffsetY,
						},
					}}
				>
					{secondLabel}
				</CustomerJourneyElement>
			</motion.div>

			<motion.div
				variants={arrowVariants}
				animate={variant}
				transition={journeyTransition}
			>
				<JourneyArrow
					start={leftRef}
					end={rightRef}
					startAnchor="top"
					endAnchor="top"
					_cpx1Offset={arrowOffsetX}
					_cpy1Offset={arrowOffsetY}
				/>
				<JourneyArrow
					start={rightRef}
					end={leftRef}
					startAnchor="bottom"
					endAnchor="bottom"
					_cpx1Offset={arrowOffsetX * -1}
					_cpy1Offset={arrowOffsetY * -1}
				/>
			</motion.div>
		</>
	);
}
