import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	projects: [],
	selected: {},
	previouslySelected: {},
	isLoading: false,
	heroImagesLoaded: {},
	error: null,
};

export const fetchProjects = createAsyncThunk(
	"project/fetchProjects",
	async (useDraft) => {
		const projectsUrl = `${
			process.env.REACT_APP_BACKEND_URL
		}/api/projects?populate=deep&sort=rank:asc${
			useDraft ? "&publicationState=preview" : ""
		}`;
		const res = await axios(projectsUrl);
		const data = await res.data;
		return data?.data ? data.data.map(project => ({...project.attributes, id: project.id})) : [];
	},
);

export const projectSlice = createSlice({
	name: "project",
	initialState,
	reducers: {
		setSelectedBySlug: (state, action) => {
			const project = state.projects.find(
				(project) => project.slug === action.payload,
			);
			state.selected = project;
		},
		setHeroImageLoaded: (state, action) => {
			state.heroImagesLoaded[action.payload.projectId] = action.payload.value;
		},
		setPreviouslySelected: (state, action) => {
			state.previouslySelected = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProjects.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchProjects.fulfilled, (state, action) => {
			state.isLoading = false;
			state.projects = action.payload;
		});
		builder.addCase(fetchProjects.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
	},
});

export const { setSelectedBySlug, setPreviouslySelected, setHeroImageLoaded } = projectSlice.actions;

export default projectSlice.reducer;
