import { motion, useAnimation } from "framer-motion";
import React, { forwardRef, useEffect, useState } from "react";
import { useXarrow } from "react-xarrows";
import { journeyTransition } from "./CustomerJourney.jsx";

export const CustomerJourneyElement = forwardRef(
	function CustomerJourneyElement(props, ref) {
		const {
			children,
			type = "primary",
			inverted = false,
			variant,
			variants,
		} = props;

		const animationControls = useAnimation();
		const updateXarrow = useXarrow();
		const [color, setColor] = useState("");
		const [backgroundColor, setBackgroundColor] = useState("");
		const [borderColor, setBorderColor] = useState("");

		// console.log(variants[variant]);
		useEffect(() => {
			if (inverted) {
				setColor(`text-${type}-100`);
				setBackgroundColor("bg-white");
				setBorderColor(`border-${type}-100`);
			} else {
				setColor("text-white");
				setBackgroundColor(`bg-${type}-100`);
				setBorderColor(`border-${type}-100`);
			}

			if (type === "secondary") {
				setColor("text-black");
			}
		}, [type, inverted]);

		useEffect(() => {
			if (variant) animationControls.start(variant);
		}, [variant, animationControls]);

		function onUpdate() {
			updateXarrow();
		}

		return (
			<motion.div
				initial={"journeyDetails"}
				variants={variants}
				animate={animationControls}
				onUpdate={onUpdate}
				className={`flex font-semibold border-2 items-center justify-center rounded-full ${color} ${borderColor} ${backgroundColor}`}
				ref={ref}
				transition={journeyTransition}
			>
				{children}
			</motion.div>
		);
	},
);
