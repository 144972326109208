import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectContentItem from "../project/ProjectContentItem";
import ProjectImage from "../project/ProjectImage";
import Markdown from "./Markdown";
import { fetchContentElement } from "./cmsSlice";

export default function CmsElement(props) {
	const { className, slug, placeholder } = props;
    const dispatch = useDispatch();
	const elementContent = useSelector((state) => state.cms.content[slug]);

	useEffect(() => {
		if (slug && !elementContent) dispatch(fetchContentElement(slug));
	}, [slug, dispatch, elementContent]);

	return (
		<article className={className}>
			<div className="flex flex-col sm:flex-row">
				<Markdown className="rich-text">
					{elementContent?.content ? elementContent.content : placeholder}
				</Markdown>

				{elementContent?.image && (
					<div className="pt-8 sm:ml-8 sm:pt-0">
						<ProjectImage
							image={elementContent.image}
							heightClass="max-h-96 sm:max-h-64"
						/>
					</div>
				)}
			</div>

			{elementContent?.components?.map((contentItem, index) => {
				return (
					<div className="sm:px-8 bg-gray-50">
						<ProjectContentItem
							key={`${index}-${contentItem.id}`}
							contentItem={contentItem}
						/>
					</div>
				);
			})}
		</article>
	);
};