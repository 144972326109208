import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Project } from "./Project";
import { fetchProjects, setSelectedBySlug } from "./projectSlice";

export default function ProjectOverview() {
	const { slug } = useParams();
	const projects = useSelector((state) => state.project.projects);
	const useDraft = useSelector((state) => state.page.useDraft);
	const projectRef = useRef(null);
	const dispatch = useDispatch();
	let location = useLocation();

	useEffect(() => {
		if (useDraft !== null && (!projects || projects.length === 0))
			dispatch(fetchProjects(useDraft));
	}, [dispatch, useDraft, projects]);

	useEffect(() => {
		dispatch(setSelectedBySlug(slug));
	}, [slug, dispatch, projects]);

	return (
		<div className="px-6 py-6 min-h-[300px] md:px-12 lg:pb-24 bg-gray-50" id="work">
			{projects && projects.length > 0 && (
				<section className="container">
					<ul className="grid gap-8 sm:grid-cols-2">
						{projects.map((project) => {
							const isSelected = project?.slug === slug;
							return (
								<li
									key={project.id}
									id={project.id}
									className="relative grid"
								>
									<Project
										project={project}
										isSelected={isSelected}
										slug={slug}
										ref={projectRef}
									/>

									{!slug && (
										<Link
											to={`/project/${project.slug}`}
											state={{
												previousPath: location.pathname,
											}}
											className="absolute top-0 bottom-0 left-0 right-0"
										/>
									)}

									{/* <Link
										to={`project/${project.attributes.slug}`}
										className="grid grid-rows-[min-content_auto]"
									>
										{project.attributes.image?.data
											?.attributes && (
											<div className="w-full overflow-hidden bg-gray-200 h-60 aspect-h-1 aspect-w-1 aspect-none group-hover:opacity-75 md:h-80">
												<img
													src={bestImageSizeFullUrl(
														project.attributes.image
															?.data?.attributes,
														"medium",
													)}
													srcSet={bestImageSizeFullUrl(
														project.attributes.image
															?.data?.attributes,
														"medium",
													)}
													loading="lazy"
													alt={
														project.attributes.name
													}
													className="object-cover object-center w-full h-full transition duration-200 ease-in-out bg-primary-50 lg:h-full lg:w-full hover:scale-105"
												/>
											</div>
										)}

										<div className="flex flex-col p-6">
											<h4 className="pb-1 text-xl font-semibold">
												{project.attributes.name}
											</h4>

											{project.attributes.summary && (
												<div>
													{project.attributes.summary}
												</div>
											)}

											<div className="pt-3 mt-auto mr-auto">
												<Label
													icon="company"
													colors="text-black bg-gray-100"
												>
													{
														project?.attributes
															.company
													}
												</Label>
											</div>
										</div>
									</Link> */}
								</li>
							);
						})}
					</ul>
				</section>
			)}
		</div>
	);
}
