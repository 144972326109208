import React from "react";
import { Hero } from "./Hero";
import { ProjectFooter } from "./ProjectFooter";
import { ProjectHeader } from "./ProjectHeader";

export function ProjectCard({ project, isSelected }) {
	return (
		<>
			<ProjectHeader project={project} isSelected={isSelected} />
			<Hero project={project} isSelected={isSelected} />
			<ProjectFooter project={project} isSelected={isSelected} />
		</>
	);
}
