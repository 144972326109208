import React, { useState } from "react";
import ChevronSolidIcon from "../icons/ChevronSolidIcon";
import Markdown from "./Markdown";

export default function Explainer(props) {
	const { title, content, contentType } = props;
	const [isOpen, setIsOpen] = useState(false);

	const markDownBodyString = content.replace(/(?:\r\n|\r|\n)/g, "\n\n");

	return (
		<span>
			<span
				onClick={() => setIsOpen(!isOpen)}
				className={`inline-flex items-center underline-offset-2 ${contentType==='highlight' ? 'underline decoration-dotted decoration-1' : 'no-underline text-tertiary-100'} hover:underline hover:decoration-dotted hover:cursor-pointer`}
			>
				<span>{title}</span>
				<div className={`flex items-center w-4 h-4 p-[3px] ml-1 -mt-1 ${contentType==='highlight' ? 'bg-primary-10' : 'bg-gray-100'} rounded-2xl ${isOpen && '-scale-y-100'}`}>
					<ChevronSolidIcon />
				</div>
			</span>
			{isOpen && (
				<span className={`block p-6 my-2 ${contentType==='highlight' ? 'bg-primary-20' : 'bg-tertiary-10'} font-light text-base`}>
					<Markdown>{markDownBodyString}</Markdown>
				</span>
			)}
		</span>
	);
}
