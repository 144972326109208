import React from "react";
import Markdown from "../content/Markdown";
import Results from "./Results";

export function ProjectDetails({ project }) {
	return (
		<div className="relative px-6 pb-8 bg-gray-50 md:px-12 md:pb-16">
			<div className="container grid grid-cols-1 gap-6 mb-6 bg-transparent md:gap-12 md:grid-cols-2 lg:grid-cols-3 md:mb-0">
				{project?.role && (
					<div className="p-6 mt-6 mb-6 bg-gray-200 md:mb-0 md:mt-0">
						<div className="pb-1 text-lg font-semibold uppercase">
							My contribution
						</div>
						<Markdown>{project?.role}</Markdown>
					</div>
				)}

				<div className="flex flex-col p-0 pb-6 md:p-6">
					{project?.details
						?.filter((item) => item.content !== "")
						.map((item, index) => (
							<div key={index} className="pb-6 last:pb-0">
								<div
									className={
										"pb-1 text-lg font-semibold uppercase"
									}
								>
									{item.title}
								</div>
								<Markdown>{item.content}</Markdown>
							</div>
						))}
				</div>

				<Results
					results={project?.results}
					className="p-0 lg:col-start-3 lg:py-6"
				/>
			</div>
		</div>
	);
}
