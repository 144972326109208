import Cookies from "js-cookie";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Routes from "./app/Routes";
import Header from "./components/navigation/Header";
import "./index.scss";
import { setUseDraft } from "./pages/pageSlice";

function App() {
	let [searchParams] = useSearchParams();
	const dispatch = useDispatch();

	useEffect(() => {
        let useDraft = false
        
        if (searchParams.get("draft")) {
            useDraft = searchParams.get("draft") === "true";
        } else {
            useDraft = Cookies.get("draft") === "true"
        }
        
		dispatch(setUseDraft(useDraft));
	}, [searchParams, dispatch]);

    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);
        }
    }, [])
    
	return (
		<>
			<Header />
			<Routes />
		</>
	);
}

export default App;
