import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
	useDraft: null,
    pages: {},
	isLoading: false,
	error: null,
};

export const fetchPage = createAsyncThunk(
	"page/fetchPage",
	async (slug) => {
		const pageUrl = `${process.env.REACT_APP_BACKEND_URL}/api/slugify/slugs/page/${slug}?populate=deep`;
		const response = await axios(pageUrl);
		return response?.data?.data?.attributes;
	},
);

export const pageSlice = createSlice({
	name: "page",
	initialState: initialState,
	reducers: {
		setUseDraft: (state, action) => {
			Cookies.set("draft", action.payload);
			state.useDraft = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPage.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchPage.fulfilled, (state, action) => {
			state.isLoading = false;
            if (action.payload.slug) {
    			state.pages[action.payload.slug] = action.payload;
            }
		});
		builder.addCase(fetchPage.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
	},
});

export const { setUseDraft } = pageSlice.actions;

export default pageSlice.reducer;
