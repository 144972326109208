import React from "react";
import Markdown from "../content/Markdown";
import { icons } from "../icons/icons";

export default function Results(props) {
	const { results, showTitle = true, className, size } = props;

	return (
		<>
			{results && (
				<div className={className}>
					{showTitle && (
						<div className={`pb-1 text-lg font-semibold uppercase`}>
							Results
						</div>
					)}
					<div className="grid grid-cols-1 gap-3">
						{results.map((item, index) => (
							<div
								className={`flex flex-row items-center ${size==='compact' ? 'px-4 py-3' : 'px-5 py-5'} bg-primary-10 grow`}
								key={index}
							>
								{item.icon && (
									<div className={`flex ${size==='compact' ? 'w-8 h-8 mr-3' : 'w-10 h-10 mr-4'} h-full justify-center text-primary-100 items-center`}>
										{icons[item.icon]}
									</div>
								)}
								<div className="flex-1">
									<Markdown
                                        className={`${item.icon ? '' : 'leading-5'}`}
                                        classNameOverrides={{ p: "" }}
                                    >
                                        {item.description}
                                    </Markdown>

									<div className={`${size==='compact' ? 'text-xl' : 'text-2xl'} font-semibold text-primary-100 flex`}>
										{item.value}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
}
