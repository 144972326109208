import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectContentItem from "../components/project/ProjectContentItem";
import Page from "./Page";
import { fetchPage } from "./pageSlice";

export default function AboutPage(props) {
	const content = useSelector((state) => state.page.pages.about);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchPage("about"));
	}, [dispatch]);

	return (
		<Page id="about" transitionDirection="right">
			{content && (
				<div className="pt-12">
					<div className="px-6 text-lg md:px-12">
						<div className="container">
							<div className="text-xl font-semibold uppercase text-md">
								{content.title}
							</div>
						</div>
					</div>

					<div className="w-screen max-w-full">
						{content.components?.map((contentItem, index) => {
							return (
								<ProjectContentItem
									key={`${index}-${contentItem.id}`}
									contentItem={contentItem}
									content={content.components}
									index={index}
								/>
							);
						})}
					</div>
				</div>
			)}
		</Page>
	);
}
