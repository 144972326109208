import React from "react";

export default function ChevronSolidIcon() {
	return (
		<svg
			width="22"
			height="12"
			viewBox="0 0 22 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M11 12L21.3923 0H0.607696L11 12Z" fill="currentColor" />
		</svg>
	);
}
