import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Lightbox from "../components/lightbox/Lightbox";
import Footer from "../components/navigation/Footer";
import {
    setScrollPosition,
    setScrollTop,
} from "../components/scroll/scrollSlice";
import {
    pageFadeVariants,
    pageTransition,
} from "../utils/common";

export default function Page(props) {
	const { children, transitionDirection, ...rest } = props;
	const dispatch = useDispatch();
	const { scrollY, scrollYProgress } = useScroll();
	const location = useLocation();
	const locationArr = location.pathname?.split("/") ?? [];
	const usePageTransition =
		(location.state?.previousPath?.startsWith("/") && locationArr[1] === "project") ||
		(location.state?.previousPath?.startsWith("/project/") && locationArr[1] === "")
			? false
			: true;

	useMotionValueEvent(scrollY, "change", (latest) => {
		dispatch(setScrollPosition(latest));
	});

	useMotionValueEvent(scrollYProgress, "change", (latest) => {
		dispatch(setScrollTop(latest));
	});

	return (
		<motion.div
			{...rest}
			className="absolute w-screen max-w-full md:mt-24"
			initial={usePageTransition ? "hidden" : ""}
			animate={usePageTransition ? "visible" : ""}
			exit={usePageTransition ? "hidden" : ""}
			variants={pageFadeVariants}
			transition={pageTransition}
		>
			<div className="min-h-screen">{children}</div>
			<Lightbox />
			<Footer />
		</motion.div>
	);
}
