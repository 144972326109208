import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import React from "react";
import { useDispatch } from "react-redux";
import { setScrollPosition, setScrollTop } from "../scroll/scrollSlice.js";

export function ProgressBar({ containerRef }) {
	const { scrollY, scrollYProgress } = useScroll({
		layoutEffect: false,
		container: containerRef,
	});
	const dispatch = useDispatch();

	useMotionValueEvent(scrollY, "change", (latest) => {
		dispatch(setScrollPosition(latest));
	});

	useMotionValueEvent(scrollYProgress, "change", (latest) => {
		dispatch(setScrollTop(latest));
	});

	return (
		<motion.div
			style={{ scaleX: scrollYProgress }}
			className="fixed w-screen max-w-full h-[4px] md:h-[3px] bg-primary-100 z-40 origin-left"
		/>
	);
}
