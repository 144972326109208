import React from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config.js";

const fullConfig = resolveConfig(tailwindConfig);

export default function Logo() {
	const colorPrimary = fullConfig.theme.colors.primary["100"];
	const colorSecondary = fullConfig.theme.colors.secondary["100"];
	const colorTertiary = fullConfig.theme.colors.tertiary["100"];
	const colorPrimarySecondary = fullConfig.theme.colors.primarySecondary["100"];
	const colorPrimaryTertiary = fullConfig.theme.colors.primaryTertiary["100"];

	return (
		<svg
			width="80"
			height="40"
			viewBox="0 0 80 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_560_1676)">
				<path d="M0 0L40 40H0.00448634L0 0Z" fill={colorPrimary} />
				<path d="M40 0L-1.02236e-06 40H39.9955L40 0Z" fill={colorSecondary} />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M39.9954 39.9955L19.9999 20L0.00439453 39.9955L0.00439503 40H39.9954V39.9955Z"
					fill={colorPrimarySecondary}
				/>
			</g>
			<g clipPath="url(#clip1_560_1676)">
				<path d="M80 0L40 40V0.00448634L80 0Z" fill={colorTertiary} />
				<path d="M80 40L40 5.36349e-06V39.9955L80 40Z" fill={colorPrimary} />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M40 0.00448659V39.9955H40.0045L60 20L40.0045 0.00448608L40 0.00448659Z"
					fill={colorPrimaryTertiary}
				/>
			</g>
		</svg>
	);
}
