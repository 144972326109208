import { createSlice } from "@reduxjs/toolkit";

export const scrollSlice = createSlice({
	name: "scroll",
	initialState: {
		scrollPosition: 0,
		scrollTop: 0,
	},
	reducers: {
		setScrollPosition: (state, action) => {
			state.scrollPosition = action.payload;
		},
		setScrollTop: (state, action) => {
			state.scrollTop = action.payload;
		},
	},
});

export const {
	setScrollPosition,
	setScrollTop,
} = scrollSlice.actions;

export default scrollSlice.reducer;
