import React from "react";
import Image from "../content/Image";
import Markdown from "../content/Markdown";

export default function ProjectImage(props) {
	const {
		image,
		description,
		hasLightBox,
		heightClass,
		imageClass,
	} = props;

	const propsOverrides = {
		...props,
		hasLightBox: hasLightBox ? hasLightBox : true,
		heightClass: heightClass ? heightClass : "flex max-h-128",
		imageClass: imageClass ? imageClass : "",
	};

	return (
		<div className="flex flex-col">
			<div className={`flex justify-center`}>
				{image && <Image {...propsOverrides} />}
			</div>

			{description && (
				<div className="pt-3 italic text-center">
					<Markdown>{description}</Markdown>
				</div>
			)}
		</div>
	);
}
