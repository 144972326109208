import React, { useEffect, useState } from "react";
import Button from "../content/Button";
import Image from "../content/Image";
import Markdown from "../content/Markdown";
import ProjectImage from "./ProjectImage";
import Results from "./Results";

const paddingExtraSmall = 2;
const paddingSmall = 8;
const paddingMedium = 12;
const paddingLarge = 16;

export default function ProjectContentItem(props) {
	const { project, contentItem, content, listSize, index } = props;
	const [elementClass, setElementClass] = useState("");
	const [componentType, setComponentType] = useState("");
	const [hasSidebar, setHasSidebar] = useState(true);
	const [padding, setPadding] = useState("");

	const previousType = index > 0 ? content[index - 1]?.type : null;
	const nextType =
		content && index < content.length
			? content[index + 1]?.type
			: "last-item";

	useEffect(() => {
		if (contentItem) {
			if (contentItem.type === "primary") {
				setElementClass("bg-primary-90 text-white");
			} else if (contentItem.type === "secondary") {
				setElementClass("bg-secondary-50");
			} else if (contentItem.type === "tertiary") {
				setElementClass("bg-tertiary-90 text-white");
			} else if (contentItem.type === "dark") {
				setElementClass("bg-gray-800 text-white");
			} else if (contentItem.type === "highlight") {
				setElementClass("bg-opacity-30 bg-primary-100");
			} else if (contentItem.type === "light-gray") {
				setElementClass("bg-gray-50");
			} else {
				setElementClass("");
			}

			setComponentType(contentItem.__component?.split(".")[1]);
		}
	}, [contentItem]);

	useEffect(() => {
		let paddingTop = `pt-${paddingMedium} md:pt-${paddingLarge}`;
		let paddingBottom = `pb-0`;

		if (nextType !== contentItem.type) {
			paddingBottom = `pb-${paddingMedium} md:pb-${paddingLarge}`;
		}

		if (contentItem.adjacent) {
			if (previousType === contentItem.type || index === 0) {
				paddingTop = `pt-${paddingExtraSmall} md:pt-${paddingSmall}`;
			}

			if (content.length - 1 !== index) {
                paddingBottom = `pb-0`;
            }
		}

		if (contentItem.quote) {
			paddingTop = `pt-${paddingExtraSmall} md:pt-${paddingSmall}`;
			paddingBottom = `pb-${paddingExtraSmall} md:pb-${paddingSmall}`;
		}

		setPadding(`${paddingTop} ${paddingBottom}`);
	}, [previousType, nextType, contentItem, index, content]);

	useEffect(() => {
		setHasSidebar(
			contentItem.sidebar === true || contentItem.sidebar === null,
		);
	}, [contentItem.sidebar]);
	return (
		<>
			{contentItem && (
				<div
					className={`${padding} px-6 md:px-12 ${elementClass} ${
						contentItem.class !== undefined && contentItem.class
					}`}
				>
					<div
						className={`container mx-0 md:mx-auto ${
							hasSidebar &&
							"md:grid md:grid-cols-sidebar-md lg:grid-cols-sidebar-lg"
						}`}
					>
						{hasSidebar && (
							<aside className="pr-12 font-semibold uppercase md:text-right text-md">
								{contentItem.title && (
									<div className="text-lg text-align-right">
										{contentItem.title}
									</div>
								)}
							</aside>
						)}

						<div className="flex-1">
							<>
								{!hasSidebar && contentItem.title && (
									<div className="pb-8 pr-12 text-lg font-semibold text-center uppercase text-md">
										{contentItem.title}
									</div>
								)}
								{componentType === "rich-text" && (
									<div
										className={
											contentItem.widget
												? "md:grid md:grid-cols-3 md:gap-6"
												: ""
										}
									>
										<Markdown
											listSize={
												listSize
													? listSize
													: contentItem.type
													? "large"
													: listSize
											}
											className="col-span-2"
											contentType={contentItem.type}
										>
											{contentItem.richText}
										</Markdown>

										{contentItem.widget === "results" && (
											<Results
												results={project?.results}
												showTitle={false}
												size="compact"
												className="mt-6 md:mt-0"
											/>
										)}
									</div>
								)}

								{(componentType === "image" ||
									componentType === "annotated-image") &&
									contentItem.image.data && (
										<div className="flex flex-col justify-center">
											<ProjectImage
												contentItem={contentItem}
												componentType={componentType}
												image={contentItem.image}
												description={
													contentItem.description
												}
												annotations={
													contentItem.annotations
												}
											/>
										</div>
									)}

								{componentType === "rich-text-with-media" && (
									<div className="flex flex-col pb-16 sm:flex-row">
										<div>
											<Markdown>
												{contentItem.text}
											</Markdown>

											{contentItem.link && (
												<div className="flex justify-center mt-8 mb-6 md:mb-0 sm:justify-start sm:mb-0">
													<Button
														link={
															contentItem.link
																.link
														}
														label={
															contentItem.link
																.title
														}
														icon={
															contentItem.link
																.icon
														}
													/>
												</div>
											)}
										</div>
										<div className="pt-8 sm:ml-8 sm:pt-0 g">
											<Image
												image={contentItem.media}
												heightClass=""
                                                imageClass="flex max-h-96 sm:max-h-[32rem]"
												hasLightBox={false}
											/>
										</div>
									</div>
								)}

								{(componentType === "image-list" ||
									componentType ===
										"annotated-image-list") && (
									<div
										className={`grid grid-cols-1 md:grid-cols-${contentItem.images.length} items-start gap-12 md:gap-8`}
									>
										{contentItem.images?.map(
											(image, index) => (
												<ProjectImage
													key={index}
													contentItem={contentItem}
													componentType={
														componentType ===
														"annotated-image-list"
															? "annotated-image"
															: "image"
													}
													image={image.image}
													description={
														image.description
													}
													annotations={
														image.annotations
													}
												/>
											),
										)}
									</div>
								)}

								{componentType === "quote" && (
									<div className="flex flex-col md:px-12">
										<blockquote className="flex text-3xl italic text-center ">
											<svg
												className="relative w-8 h-8 text-tertiary-100 -top-5"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="currentColor"
												viewBox="0 0 18 14"
											>
												<path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
											</svg>
											<p className="px-2 text-tertiary-100">
												{contentItem.quote}
											</p>
											<svg
												className="relative self-end w-8 h-8 text-tertiary-100 top-5"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="currentColor"
												viewBox="0 0 18 14"
											>
												<path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
											</svg>
										</blockquote>

										{contentItem.author && (
											<div className="px-12 pt-6 text-right 6mr-24 text-tertiary-100">
												{contentItem.author}
											</div>
										)}
									</div>
								)}
							</>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
