import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bestImageSizeFullUrl } from "../../utils/common";
import { showSlide } from "../lightbox/lightboxSlice";
import { setHeroImageLoaded } from "./projectSlice";

export default function HeroImage({ image, imagePosition, project }) {
	const heroImagesLoaded = useSelector((state) => state.project.heroImagesLoaded);
    const imageLoaded = heroImagesLoaded[project.id]
	const dispatch = useDispatch();

	if (!image) return null;

	return (
		<div className="flex overflow-hidden grow shrink aspect-[16/8] bg-primary-50">
			<img
				src={bestImageSizeFullUrl(image, "original")}
				srcSet={bestImageSizeFullUrl(image, "original")}
				loading="lazy"
				alt={image.name}
				className={`flex object-cover ${
					imagePosition ? imagePosition : "object-center"
				} aspect-[16/8] grow shrink lg:h-full lg:w-full duration-300 transition-opacity ${
					imageLoaded ? "opacity-100" : "opacity-0"
				} hover:cursor-zoom-in`}
				onLoad={() => dispatch(setHeroImageLoaded({ projectId: project.id, value: true }))}
				onClick={() => {
					dispatch(
						showSlide({
							src: bestImageSizeFullUrl(image, "original"),
						}),
					);
				}}
			/>
		</div>
	);
}
