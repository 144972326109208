import React from "react";
import ClickIcon from "../icons/ClickIcon";
import MoneyIcon from "../icons/MoneyIcon";
import UserIcon from "../icons/UserIcon";
import CollaborateIcon from "../icons/CollaborateIcon";
import DuplicateIcon from "../icons/DuplicateIcon";
import CompanyIcon from "../icons/CompanyIcon";
import DocumentIcon from "./DocumentIcon";
import DownloadDocumentIcon from "./DownloadDocumentIcon";
import CalendarIcon from "./CalendarIcon";

export const icons = {
	clicks: <ClickIcon />,
	money: <MoneyIcon />,
	users: <UserIcon />,
	collaborate: <CollaborateIcon />,
	duplicate: <DuplicateIcon />,
    company: <CompanyIcon />,
    document: <DocumentIcon />,
    downloadDocument: <DownloadDocumentIcon />,
    calendar: <CalendarIcon />,
    download: <DownloadDocumentIcon />,
};