import React from "react";
import { icons } from "../icons/icons";

export default function Button(props) {
	const { label, icon, className } = props;

	return (
		<a
			href={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CV_URL}`}
			target="_blank"
			rel="noreferrer"
			className={`${className} bg-primary-100 text-white inline-flex items-center px-4 py-2 hover:bg-primary-90 hover:cursor-pointer`}
		>
			<span>
				{icon && <div className="w-5 h-5 mr-2">{icons[icon]}</div>}
			</span>
            <span className="mt-1">
			{label}
            </span>
		</a>
	);
}
