/** @type {import('tailwindcss').Config} */
module.exports = {
	content: ["./src/**/*.{js,jsx,ts,tsx}"],
	theme: {
		container: {
			center: true,
			screens: {
				sm: "640px",
				md: "768px",
				lg: "1024px",
				xl: "1100px",
				"2xl": "1100px",
			},
		},
		extend: {
			fontFamily: {
				sans: [
					"Khula",
					"system-ui",
					"-apple-system",
					"BlinkMacSystemFont",
					'"Segoe UI"',
					"Roboto",
					"Ubuntu",
					'"Helvetica Neue"',
					"sans-serif",
				],
			},
			fontWeight: 200,
			colors: {
				primary: {
					5: "#F4F9FA",
					10: "#EBF4F5",
					20: "#D6E8EB",
					30: "#C2DDE1",
					40: "#ADD1D6",
					50: "#99C5CC",
					60: "#85B9C2",
					70: "#71AEB8",
					80: "#5CA2AD",
					90: "#4897A4",
					100: "#338B99",
				},
				secondary: {
					5: "#FEFCF4",
					10: "#FFFAEB",
					20: "#FEF5D5",
					30: "#FDF0C1",
					40: "#FCEAAB",
					50: "#FCE597",
					60: "#FBE082",
					70: "#FBDB6D",
					80: "#F9D558",
					90: "#F9D143",
					100: "#F8CB2E",
				},
				tertiary: {
					5: "#FFF7F6",
					10: "#FFF0ED",
					20: "#FFDFDA",
					30: "#FFD0C8",
					40: "#FFBFB5",
					50: "#FFB0A3",
					60: "#FFA091",
					70: "#FF907F",
					80: "#FF806C",
					90: "#FF705A",
					100: "#FF6047",
				},
				primarySecondary: {
					100: "#82A56E",
				},
				primaryTertiary: {
					100: "#59696B",
				},
				secondaryTertiary: {
					100: "#FB963A",
				},
			},
			maxHeight: {
				128: "32rem",
			},
			gridTemplateColumns: {
				"sidebar-md": "150px 1fr",
				"sidebar-lg": "250px 1fr",
			},
		},
	},
	plugins: [require("@tailwindcss/aspect-ratio")],
	safelist: [
        "font-light",
		"pt-2",
		"pt-6",
		"pt-8",
		"pt-12",
		"pt-16",
        "gap-[12px]",
		"text-primary-100",
		"text-secondary-100",
		"text-tertiary-100",
		"text-primarySecondary-100",
		"text-primaryTertiary-100",
		"text-secondaryTertiary-100",
		"bg-primary-100",
		"bg-secondary-100",
		"bg-tertiary-100",
		"bg-primarySecondary-100",
		"bg-primaryTertiary-100",
		"bg-secondaryTertiary-100",
		"border-primary-100",
		"border-secondary-100",
		"border-tertiary-100",
		"border-primarySecondary-100",
		"border-primaryTertiary-100",
		"border-secondaryTertiary-100",
		"items-start",
		"items-center",
		"items-end",
		"md:pt-2",
		"md:pt-6",
		"md:pt-8",
		"md:pt-12",
		"md:pt-16",
		"lg:pt-2",
		"lg:pt-6",
		"lg:pt-8",
		"lg:pt-12",
		"lg:pt-16",
		"pb-2",
		"pb-6",
		"pb-8",
		"pb-12",
		"pb-16",
		"md:pb-2",
		"md:pb-6",
		"md:pb-8",
		"md:pb-12",
		"md:pb-16",
		"lg:pb-2",
		"lg:pb-6",
		"lg:pb-8",
		"lg:pb-12",
		"lg:pb-16",
        "md:grid-cols-1",
        "md:grid-cols-2",
        "md:grid-cols-3",
        "md:grid-cols-4",
        "sm:grid-flow-col",
        "md:grid-flow-col",
        "md:auto-cols-max",
	],
};
