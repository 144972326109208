import React from "react";
import { useDispatch } from "react-redux";
import { bestImageSizeFullUrl } from "../../utils/common";
import { showSlide } from "../lightbox/lightboxSlice";

export default function Image(props) {
	const {
		image,
        imageClass = "",
        heightClass = "",
		hasLightBox,
		contentItem,
		componentType,
		annotations,
		description,
	} = props;
	const dispatch = useDispatch();

	return (
        <span className={`${heightClass}`}>
            <img
                src={bestImageSizeFullUrl(image?.data?.attributes, "medium")}
                alt={image?.data?.attributes?.name}
                onClick={() => {
                    hasLightBox &&
                        dispatch(
                            showSlide({
                                id: contentItem && contentItem.id,
                                type: componentType,
                                annotations: annotations,
                                name: contentItem && contentItem.name,
                                title: description,
                                src: bestImageSizeFullUrl(
                                    image.data.attributes,
                                    "original",
                                ),
                            }),
                        );
                }}
                className={`${imageClass} object-contain ${
                    hasLightBox && "hover:cursor-zoom-in"
                }`}
            />
        </span>
	);
}
