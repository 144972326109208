import React from "react";
import { icons } from "../icons/icons";

export default function Label(props) {
	const { icon, className, colors="bg-primary-10 text-primary-100" } = props;
	return (
		<div className={`${className} flex items-center px-3 py-1 text-sm font-medium ${colors}`}>
			{ icon && (
                <div className="w-4 h-4 mr-1">{icons[icon]}</div>
            )}

			<span className="mt-1">
                {props.children}
            </span>
		</div>
	);
}
