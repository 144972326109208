import { motion } from "framer-motion";
import { marked } from "marked";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config.js";

const fullConfig = resolveConfig(tailwindConfig);

export default function AnnotatedImage({ slide }) {
	const useDraft = useSelector((state) => state.page.useDraft);
	const containerRef = useRef();
	const imageRef = useRef();

	function logCursorPosition(event) {
		if (useDraft) {
			const xPos = event.clientX - imageRef.current?.x;
			const yPos = event.clientY - imageRef.current?.y;
			const imageWidth = imageRef?.current?.clientWidth;
			const imageHeight = imageRef?.current?.clientHeight;

			const offsetX =
				Math.abs(
					Math.round(
						imageRef.current?.getBoundingClientRect().x -
							containerRef.current?.getBoundingClientRect().x,
					),
				) / 2;
			const offsetY =
				Math.abs(
					Math.round(
						imageRef.current?.getBoundingClientRect().y -
							containerRef.current?.getBoundingClientRect().y,
					),
				) / 2;

			const xPercentage = (xPos + offsetX) / imageWidth;
			const yPercentage = (yPos + offsetY) / imageHeight;

			console.log(`"x": ${xPercentage},\n"y": ${yPercentage},`);
		}
	}

	return (
		<div className="relative yarl__slide_current yarl__flex_center">
			<div
				className="yarl__fullsize yarl__flex_center"
				// style={{ transform: "scale(1)", translateX: 0, translateY: 0 }}
				ref={containerRef}
			>
				<img
					draggable="false"
					className="yarl__slide_image"
					src={slide.src}
					style={{
						maxWidth: "100vw",
						maxHeight: "100vh",
					}}
					alt={slide.name}
					onClick={logCursorPosition}
					ref={imageRef}
				/>

				<motion.div
					initial="hidden"
					animate="visible"
					variants={{
						hidden: {},
						visible: {
							transition: {
								staggerChildren: 0.09,
								ease: "easeOut",
								delayChildren: .4,
							},
						},
					}}
				>
					{slide?.annotations?.map((annotation, index) => {
						const annotationId = `${slide.id}-${index}`;
						return (
							<motion.div
								key={`tooltip-${annotationId}`}
								variants={{
									hidden: { opacity: 0 },
									visible: { opacity: 1 },
								}}
							>
								<Tooltip
									id={`tooltip-${annotationId}`}
									className="font-medium text-white"
									style={{
										backgroundColor:
											fullConfig.theme.colors.tertiary[
												"100"
											],
										borderRadius: 0,
										padding: "0.5rem 1rem 0.3rem ",
									}}
									isOpen={true}
								/>

								<div
									className={`absolute w-[12px] h-[12px] rounded-full bg-tertiary-100 flex outline outline-[6px] outline-tertiary-100/[.5] items-center justify-center pt-1 text-white font-bold left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
									style={{
										left: `calc(${
											annotation.x * 100
										}% - 0px)`,
										top: `calc(${
											annotation.y * 100
										}% - 0px)`,
									}}
									data-tooltip-id={`tooltip-${annotationId}`}
									data-tooltip-html={marked(annotation.text)}
									data-tooltip-position-strategy="fixed"
									data-tooltip-place={annotation.position ? annotation.position : "right"}
									data-tooltip-offset={15}
								/>
							</motion.div>
						);
					})}
				</motion.div>
			</div>
		</div>
	);
}
