import React from "react";

export default function DuplicateIcon() {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.69521 10.1024V10.8524H5.44521V10.1024H4.69521ZM10.1024 4.69521V5.44521H10.8524V4.69521H10.1024ZM2.04999 2.29999C2.04999 2.16192 2.16192 2.04999 2.29999 2.04999V0.549988C1.33349 0.549988 0.549988 1.33349 0.549988 2.29999H2.04999ZM2.04999 9.10238V2.29999H0.549988V9.10238H2.04999ZM2.29999 9.35238C2.16192 9.35238 2.04999 9.24045 2.04999 9.10238H0.549988C0.549988 10.0689 1.33349 10.8524 2.29999 10.8524V9.35238ZM4.69521 9.35238H2.29999V10.8524H4.69521V9.35238ZM5.44521 10.1024V5.69521H3.94521V10.1024H5.44521ZM5.44521 5.69521C5.44521 5.55714 5.55714 5.44521 5.69521 5.44521V3.94521C4.72871 3.94521 3.94521 4.72871 3.94521 5.69521H5.44521ZM5.69521 5.44521H10.1024V3.94521H5.69521V5.44521ZM9.35238 2.29999V4.69521H10.8524V2.29999H9.35238ZM9.10238 2.04999C9.24045 2.04999 9.35238 2.16192 9.35238 2.29999H10.8524C10.8524 1.33349 10.0689 0.549988 9.10238 0.549988V2.04999ZM2.29999 2.04999H9.10238V0.549988H2.29999V2.04999Z"
				fill="currentColor"
			/>
			<path
				d="M8.46766 14.7551V15.5051H9.21766V14.7551H8.46766ZM14.7551 8.46767V9.21767H15.5051V8.46767H14.7551ZM5.44521 5.69521C5.44521 5.55714 5.55714 5.44521 5.69521 5.44521V3.94521C4.72871 3.94521 3.94521 4.72871 3.94521 5.69521H5.44521ZM5.44521 13.7551V5.69521H3.94521V13.7551H5.44521ZM5.69521 14.0051C5.55714 14.0051 5.44521 13.8932 5.44521 13.7551H3.94521C3.94521 14.7216 4.72871 15.5051 5.69521 15.5051V14.0051ZM8.46766 14.0051H5.69521V15.5051H8.46766V14.0051ZM9.21766 14.7551V9.46766H7.71766V14.7551H9.21766ZM9.21766 9.46766C9.21766 9.32959 9.32959 9.21767 9.46766 9.21767V7.71767C8.50116 7.71767 7.71766 8.50117 7.71766 9.46766H9.21766ZM9.46766 9.21767H14.7551V7.71767H9.46766V9.21767ZM14.0051 5.69521V8.46767H15.5051V5.69521H14.0051ZM13.7551 5.44521C13.8932 5.44521 14.0051 5.55714 14.0051 5.69521H15.5051C15.5051 4.72872 14.7216 3.94521 13.7551 3.94521V5.44521ZM5.69521 5.44521H13.7551V3.94521H5.69521V5.44521Z"
				fill="currentColor"
			/>
			<rect
				x="8.46766"
				y="8.46767"
				width="13.8323"
				height="13.8323"
				rx="1"
				stroke="currentColor"
				strokeWidth="1.5"
			/>
		</svg>
	);
}
