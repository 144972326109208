import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	content: {},
	isLoading: false,
	error: null,
};

export const fetchContentElement = createAsyncThunk(
	"cms/fetchContentElement",
	async (slug) => {
		const contentUrl = `${process.env.REACT_APP_BACKEND_URL}/api/slugify/slugs/block/${slug}?populate=deep`;
		const response = await axios(contentUrl);
		return response?.data?.data?.attributes;
	},
);

export const projectSlice = createSlice({
	name: "project",
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(fetchContentElement.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchContentElement.fulfilled, (state, action) => {
			state.isLoading = false;
            if (action.payload.slug) {
    			state.content[action.payload.slug] = action.payload;
            }
		});
		builder.addCase(fetchContentElement.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error.message;
		});
	},
});

export const { setContent, setPreviouslySelected } =
	projectSlice.actions;

export default projectSlice.reducer;
