import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { extendMarkdownFormatting } from "../../utils/common";
import Explainer from "./Explainer";

export default function Markdown(props) {
	const { className, listSize, contentType, classNameOverrides } = props;

	function getClassName(element, className) {
		if (classNameOverrides && classNameOverrides.hasOwnProperty(element)) {
			return classNameOverrides[element];
		}

		return className;
	}

	const allTransformations = {
		p: ({ children }) => (
			<div className={getClassName("p", "pb-4 last:pb-0")}>
				{children}
			</div>
		),
		ul: ({ children }) => (
			<ul className={getClassName("ul", "mb-6 ml-4 list-disc last:mb-0 marker:text-lg")}>
				{children}
			</ul>
		),
		ol: ({ children }) => (
			<ol className={getClassName("ol", "mb-6 ml-8 list-decimal last:mb-0 marker:font-semibold marker:text-primary-100 marker:text-lg")}>
				{children}
			</ol>
		),
		li: ({ children }) => (
			<li
				className={getClassName("li", `pl-1 ${listSize === "large" ? "mt-1 text-lg" : ""}`)}
			>
				{children}
			</li>
		),
		strong: ({ children }) => (
			<strong className={getClassName("strong", "font-semibold")}>{children}</strong>
		),
		h2: ({ children }) => (
			<h3 className={getClassName("h2", "text-2xl font-semibold uppercase text-md first:pt-0")}>
				{extendMarkdownFormatting(children)}
			</h3>
		),
		h3: ({ children }) => (
			<h3 className={getClassName("h3", "pt-5 pb-1 text-xl font-semibold text-md text-primary-100 first:pt-0")}>
				{extendMarkdownFormatting(children)}
			</h3>
		),
		h4: ({ children }) => (
			<h4 className={getClassName("h4", "pt-3 pb-1 font-semibold uppercase first:pt-0")}>
				{extendMarkdownFormatting(children)}
			</h4>
		),
		h5: ({ children }) => (
			<h5 className={getClassName("h5", "pt-1 pb-1 text-xs font-semibold uppercase")}>
				{children}
			</h5>
		),
		h6: ({ children }) => (
			<h6 className={getClassName("h6", "pt-1 font-semibold")}>
				{children}
			</h6>
		),
		blockquote: ({ children }) => (
			<div className={getClassName("blockquote", "p-8 mb-6 text-2xl bg-primary-50")}>{children}</div>
		),
		del: () => null,
		img: ({ src, alt }) => {
			src = src.replace("BACKEND_URL", process.env.REACT_APP_BACKEND_URL);
			return (
                <img 
                    src={src} 
                    alt={alt} 
                    className={getClassName("p", null)} 
                />
            );
		},
		a: ({ href, children, title }) => {
			if (href === "explainer") {
				return (
					<Explainer
						title={children}
						content={title}
						contentType={contentType}
					/>
				);
			}

			return (
				<a
					href={href}
					className={getClassName("a", "underline underline-offset-2 text-tertiary-100 decoration-dotted")}
					target="_blank"
					rel="noreferrer"
				>
					{children}
				</a>
			);
		},
	};

	return (
		<ReactMarkdown
			components={allTransformations}
			className={className}
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeRaw]}
		>
			{props.children}
		</ReactMarkdown>
	);
}
